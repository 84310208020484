[data-v-cafb4c72] .treeselect-todo .vue-treeselect__control {
  height: 40px;
  padding: 2px;
}
.process-inst-title[data-v-cafb4c72] {
  margin-left: 10px;
  cursor: pointer;
}
.process-inst-title[data-v-cafb4c72]:hover {
    color: #409eff;
    text-decoration: underline;
}
.unselect-search-area[data-v-cafb4c72] {
  position: absolute;
  will-change: top, left;
  -webkit-transform-origin: center top;
          transform-origin: center top;
  min-height: 240px;
  padding: 14px 0px;
  margin: 0px;
  top: 40px;
  overflow-x: hidden;
  background-color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 900;
}
.unselect-search-area .unselect-search-area-form[data-v-cafb4c72] {
    padding: 0px 14px;
}
.unselect-search-area .unselect-search-area-form .ivu-form-item[data-v-cafb4c72] {
      margin-bottom: 10px;
}
.unselect-search-area .unselect-search-area-form .ivu-form-item[data-v-cafb4c72] .ivu-input {
        height: 32px;
}
.unselect-search-area .unselect-search-area-footer[data-v-cafb4c72] {
    position: absolute;
    bottom: 4px;
    width: 100%;
    text-align: center;
    border-top: 1px solid #dcdee2;
}
.unselect-search-area .unselect-search-area-footer .ivu-btn[data-v-cafb4c72] {
      margin-left: 14px;
}
.sj-table[data-v-cafb4c72] {
  overflow-y: auto;
}
.sj-table .item-icon--unread[data-v-cafb4c72] {
    height: 32px;
    position: absolute;
    bottom: 4px;
}
.sj-table[data-v-cafb4c72] .el-table__row td {
    overflow-x: hidden;
}
.task-main[data-v-cafb4c72] {
  margin-top: 10px;
  height: calc(100% - 120px);
}
.task-main .task-main-left[data-v-cafb4c72] {
    height: calc(100vh - 250px);
    background: #fff;
    padding: 14px;
}
.task-main .task-main-left .task-main-left-tree[data-v-cafb4c72] {
      height: calc(100% - 50px);
      overflow-y: auto;
}
.task-main .task-main-right[data-v-cafb4c72] {
    height: calc(100vh - 250px);
    background: #fff;
    padding: 14px;
}
